import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { getCard, reportFraudulent } from './home.actions'
import { initialState } from './home.state'
import type { IError, IFormPayload } from './type'

const slice = createSlice({
  name: 'homeReducer',
  initialState,
  reducers: {
    setPayload: (state, { payload }: PayloadAction<IFormPayload>) => {
      state.payload = payload
    },
    clearError: (state) => {
      state.error = ''
    },
  },
  extraReducers(builder) {
    // Get Cards
    builder.addCase(getCard.pending, (state) => {
      state.loading = true
      state.cardsResponseData.cards = []
      state.cardsResponseData.scheme.schemeIdentifier = ''
      state.cardsResponseData.scheme.schemeName = ''
    })
    builder.addCase(getCard.fulfilled, (state, { payload }) => {
      state.loading = false
      if (payload) {
        state.cardsResponseData = payload

        // separate active and other cards
        const activeCards = payload.cards.filter((card) => {
          if (card.dateOfExpiry === null || card.dateOfExpiry === '') {
            if (card.isValid) {
              return true
            }
          }

          const today = new Date()
          today.setHours(0, 0, 0, 0)
          const expiredDate = new Date(card.dateOfExpiry)
          expiredDate.setHours(0, 0, 0, 0)

          return expiredDate >= today && card.isValid
        })

        const expiredAndCanclled = payload.cards.filter((card) => {
          if (card.dateOfExpiry === null || card.dateOfExpiry === '') {
            if (!card.isValid) {
              return true
            }
            if (card.isValid) {
              return false
            }
          }

          const today = new Date()
          today.setHours(0, 0, 0, 0)
          const expiredDate = new Date(card.dateOfExpiry)
          expiredDate.setHours(0, 0, 0, 0)

          if (expiredDate < today) {
            card.isExpired = true
          }

          return !card.isValid || card.isExpired
        })

        state.activeCards = activeCards
        state.expiredOrCancelledCards = expiredAndCanclled
      }
      state.error = ''
    })
    builder.addCase(getCard.rejected, (state, { payload }) => {
      state.loading = false
      if (payload) {
        const { message = 'Something went wrong' } = payload as IError
        state.error = message
      }
      state.activeCards = []
      state.expiredOrCancelledCards = []
    })

    // Report Fraudluent
    builder.addCase(reportFraudulent.pending, (state) => {
      state.loading = true
    })
    builder.addCase(reportFraudulent.fulfilled, (state, { payload }) => {
      state.loading = false
      console.log('RESPONSE PAYLOAD ==> ', payload)
    })
    builder.addCase(reportFraudulent.rejected, (state, { payload }) => {
      state.loading = false
      if (payload) {
        const { message = 'Something went wrong' } = payload as IError
        state.error = message
      }
    })
  },
})

export const { setPayload, clearError } = slice.actions

export default slice.reducer
